/* eslint-disable max-lines */
import { RWebShare } from 'react-web-share';
import { getProjectStatuses } from '@services/admin.service';
import { useQuery } from '@tanstack/react-query';
import Icon from '@library/Icon/Icon';
import style from '@css/theme.style.json';

import './project-info.styl';

function ProjectInfo({ 
  activeProject, mapOptions, setFormIsOpen, setSidepanelRightIsOpen, 
  setSidepanelLeftIsOpen,
}) {
  const { data: projectStatusesData } = useQuery({
    queryKey: ['projectStatuses'],
    queryFn: getProjectStatuses,
  });

  if (!projectStatusesData) return null;

  return (
    <div className="project-info">
      <div className="project-info__header">
        <div className="project-info__header__value-chain">
          <Icon className="project-info__header__value-chain__marker" icon={`../markers/value-chain-${activeProject?.properties?.value_chain}.png`} />
          <span className="project-info__header__value-chain__text">{activeProject?.properties?.value_chain_full}</span>
        </div>
        <div className="project-info__header__logo" />
      </div>
      <h2 className="project-info__title">
        {activeProject?.properties?.name}
      </h2>
      <h4 className="project-info__application">
        {activeProject?.properties?.application?.data?.attributes?.name}
      </h4>
      <div className="project-info__status">
        <div className="project-info__status__row">
          <div>2024</div>
          <div>2026</div>
          <div>2028</div>
          <div>{'>2030'}</div>
        </div>
        <div className="project-info__status__row">
          <p>
            <span 
              style={{ 
                backgroundColor: style?.color?.project
                  ?.[activeProject?.properties?.status_2024], 
              }}
            />
            {projectStatusesData
              .find(s => s.id === activeProject?.properties?.status_2024).attributes.name}
          </p>
          <p>
            <span 
              style={{ 
                backgroundColor: style?.color?.project
                  ?.[activeProject?.properties?.status_2026], 
              }}
            />
            {projectStatusesData
              .find(s => s.id === activeProject?.properties?.status_2026).attributes.name}
          </p>
          <p>
            <span 
              style={{ 
                backgroundColor: style?.color?.project
                  ?.[activeProject?.properties?.status_2028], 
              }}
            />
            {projectStatusesData
              .find(s => s.id === activeProject?.properties?.status_2028).attributes.name}
          </p>
          <p>
            <span 
              style={{ 
                backgroundColor: style?.color?.project
                  ?.[activeProject?.properties?.status_2030], 
              }}
            />
            {projectStatusesData
              .find(s => s.id === activeProject?.properties?.status_2030).attributes.name}
          </p>
        </div>
      </div>
      <div className="project-info__block">
        <h3 className="project-info__block__title">
          About
        </h3>
        <div className="project-info__block__text">
          {activeProject?.properties?.long_description 
            && mapOptions?.show_project_long_description ? (
              <span>{activeProject?.properties?.long_description}</span>
            ) : (
              <span>{activeProject?.properties?.short_description}</span>
            )}
        </div>
      </div>
      {activeProject?.properties?.location && (
        <div className="project-info__block">
          <h3 className="project-info__block__title">
            Location
          </h3>
          <p>
            {activeProject.properties.location}
          </p>
        </div>
      )}
      {activeProject?.properties?.partners?.data.length && (
        <div className="project-info__block">
          <h3 className="project-info__block__title">
            Partners
          </h3>
          <ul className="project-info__block__list">
            {activeProject?.properties?.partners.data.map((p) => {
              return (
                <li key={p.attributes.name} className="project-info__block__list__item">
                  <span>{p.attributes.name}</span>
                </li>
              )
            })}
          </ul>
        </div>
      )}
      {((activeProject?.properties?.contacts && mapOptions?.show_project_contact_info) 
        || (activeProject?.properties?.website && mapOptions?.show_project_website)) && (
        <div className="project-info__find-out">
          <h4>Find out more?</h4>
          <p>{`Please get in touch for more information about ${activeProject?.properties?.name}.`}</p>
          <div className="project-info__find-out__website">
            {activeProject?.properties?.website && mapOptions?.show_project_website && (
              <a 
                href={activeProject?.properties?.website} 
                className="project-info__find-out__website__url"
                target="_blank"
                rel="noreferrer"
              >
                <Icon 
                  icon="link.svg"
                  className="project-info__find-out__website__icon"
                />
                {'Visit website >'}
              </a>
            )}
            {activeProject?.properties?.contacts && mapOptions?.show_project_contact_info && (
              <div className="project-info__find-out__website__url">
                <Icon 
                  icon="email.svg"
                  className="project-info__find-out__website__icon"
                />
                {activeProject?.properties?.contacts}
              </div>
            )}
          </div>
        </div>
      )}
      {activeProject?.properties?.sponser_logo?.data?.attributes?.url && (
        <div 
          className="project-info__logo"
          style={{
            backgroundImage: `url(${activeProject?.properties
              ?.sponser_logo?.data?.attributes?.url})`, 
          }}
        />
      )}
      <div className="project-info__change-information">
        <Icon 
          icon="pen.svg"
          className="project-info__change-information__icon"
        />
        <h4>Change information</h4>
        <p>
          <span>
            Is certain information incorrect (anymore)? 
            Please let us know by clicking on the link below.
          </span>
        </p>
        <button 
          type="button"
          className="project-info__change-information__button"
          onClick={() => {
            setFormIsOpen(activeProject);
            setSidepanelLeftIsOpen(false);
            setSidepanelRightIsOpen(false);
          }}
        >
          Request changes
        </button>
      </div>
    </div>
  )
}

export default ProjectInfo;