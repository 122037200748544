/* eslint-disable max-lines */
import {
  useState, useEffect, useCallback, useMemo, useRef,
} from 'react';
import {
  useParams, useSearchParams, useNavigate, useLocation, 
} from 'react-router-dom';
import KDBush from 'kdbush';
import { around } from 'geokdbush-tk';
import { useQuery } from '@tanstack/react-query'
import {
  getPipelines, getProjects, getApplications, getProjectStatuses, 
} from '@services/admin.service';
import Button from '@library/Button/Button';

import Map from './Map/Map';
import DataControls from './DataControls/DataControls';
import SidepanelLeft from './SidepanelLeft/SidepanelLeft';
import SidepanelRight from './SidepanelRight/SidepanelRight';
import Popup from './Popup/Popup';
import Logos from './Logos/Logos';
import Form from './Form/Form';

import './map-view.styl';

function MapView() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const initPathname = useRef(pathname);
  const [searchParams] = useSearchParams();
  const { projectSlug } = useParams();
  const initProjectSlug = useRef(projectSlug);
  const showFullscreenControlRef = useRef(searchParams.get('showFullscreenControl'));
  const [map, setMap] = useState();
  const [sidepanelLeftIsOpen, setSidepanelLeftIsOpen] = useState(false);
  const [sidepanelLeftContent, setSidepanelLeftContent] = useState(false);
  const [sidepanelRightIsOpen, setSidepanelRightIsOpen] = useState();
  const [formIsOpen, setFormIsOpen] = useState(false);
  const [activeProject, setActiveProject] = useState();
  const [activeStatus, setActiveStatus] = useState('2024');
  const [searchValue, setSearchValue] = useState('');
  const [filters, setFilters] = useState({
    'value-chain': [],
    application: [],
    location: { place: null, range: 10 },
    partners: [],
    status: [],
  });
  const prevFilters = useRef();

  const { data: projectData } = useQuery({
    queryKey: ['projectData'],
    queryFn: getProjects,
  });

  const { data: pipelineData } = useQuery({
    queryKey: ['pipelineData'],
    queryFn: getPipelines,
  });

  useQuery({
    queryKey: ['applicationsData'],
    queryFn: getApplications,
  });

  useQuery({
    queryKey: ['projectStatuses'],
    queryFn: getProjectStatuses,
  });
  
  const projects = useMemo(() => {
    if (!projectData) return null;
    let preppedFeatures = projectData.features;
    // filter on search
    preppedFeatures = preppedFeatures.filter((f) => {
      return f.properties.name.toLowerCase().includes(searchValue.toLowerCase()) 
      || f.properties?.short_description?.toLowerCase().includes(searchValue.toLowerCase())
      || f.properties?.long_description?.toLowerCase().includes(searchValue.toLowerCase())
      || f.properties?.partners?.data.find(
        p => p?.attributes?.name?.toLowerCase().includes(searchValue.toLowerCase()),
      );
    });
    // filter on value chain
    if (filters['value-chain'].length) {
      preppedFeatures = preppedFeatures.filter((f) => {
        return filters['value-chain'].includes(f.properties.value_chain);
      })
    }
    // filter on application
    if (filters.application.length) {
      preppedFeatures = preppedFeatures.filter((f) => {
        return filters.application.includes(f.properties?.application?.data?.id);
      })
    }
    // filter on location
    if (filters.location.place) {
      if (filters.location.place?.data?.bbox 
        && filters?.location?.place?.data?.id 
        !== prevFilters?.current?.location?.place?.data?.id) {
        const [lat1, lng1, lat2, lng2] = filters.location.place.data.bbox;
        if (lat1 && lng1 && lat2 && lng2) {
          map.fitBounds([[lat1, lng1], [lat2, lng2]], { padding: { left: 450 } });
        }
      }

      if (filters.location.place?.data?.geometry?.coordinates) {
        const [lat, lng] = filters.location.place.data.geometry.coordinates;
        
        const index = new KDBush(preppedFeatures.length);
        preppedFeatures.forEach(f => index.add(
          f.geometry.coordinates[0], 
          f.geometry.coordinates[1],
        ));
        index.finish();
        
        const nearestIndexes = around(index, lat, lng, Infinity, filters.location.range);
        preppedFeatures = nearestIndexes.map(i => preppedFeatures[i]);
      }
    }
    // filter on application
    if (filters.partners.length) {
      preppedFeatures = preppedFeatures.filter((f) => {
        const partnerArray = f.properties?.partners?.data;
        return partnerArray.find((p) => {
          return filters.partners.map(filter => filter.value).includes(p.attributes.name);
        });
      })
    }
    // filter on project status
    if (filters.status.length) {
      preppedFeatures = preppedFeatures.filter((f) => {
        return filters.status.includes(`${f.properties[`status_${activeStatus}`]}`);
      })
    }
    // set active status for markers
    preppedFeatures = preppedFeatures.map(f => ({ 
      ...f, 
      properties: { 
        ...f.properties, 
        status: f.properties[`status_${activeStatus}`], 
      }, 
    }));
    prevFilters.current = filters;
    return { ...projectData, features: preppedFeatures };
  }, [map, projectData, activeStatus, searchValue, filters]);

  const piplines = useMemo(() => {
    if (!pipelineData) return null;
    let preppedFeatures = pipelineData.features;

    // set active status for pipelines
    preppedFeatures = preppedFeatures.map(f => ({ 
      ...f, 
      properties: { 
        ...f.properties, 
        status: f.properties[`status_${activeStatus}`], 
      }, 
    }));
    return { ...pipelineData, features: preppedFeatures };
  }, [pipelineData, activeStatus]);

  const handleMarkerClick = useCallback((feature) => {
    if (!feature) return;
    setTimeout(() => setActiveProject(feature), 300);
  }, []);

  useEffect(() => {
    if (projectData && initProjectSlug.current) {
      setActiveProject(projectData.features.find((f) => {
        return f.properties.slug === initProjectSlug.current;
      }));
      setSidepanelRightIsOpen(true);
      initProjectSlug.current = null;
    }
  }, [projectData]);

  useEffect(() => {
    if (pipelineData && initPathname.current === '/pipelines') {
      setActiveProject(pipelineData?.features[0]);
      setSidepanelRightIsOpen(true);
      initPathname.current = null;
    }
  }, [pipelineData]);

  useEffect(() => {
    if (!sidepanelRightIsOpen) {
      setActiveProject(null);
    }
  }, [sidepanelRightIsOpen]);

  useEffect(() => {
    if (activeProject && sidepanelRightIsOpen) {
      if (activeProject?.properties?.slug) {
        navigate(`/project/${activeProject.properties.slug}`, { replace: true });
      } else {
        navigate('/pipelines', { replace: true });
      }
    } else if (!sidepanelRightIsOpen 
      && !initProjectSlug.current && !initPathname.current) {
      navigate('/', { replace: true });
    }
  }, [navigate, activeProject, sidepanelRightIsOpen]);

  useEffect(() => {
    if (!map || !projects) return;

    map.getSource('projects').setData(projects);

    map.on('click', (e) => {
      setActiveProject(null);
      navigate('/', { replace: true });
      const features = map.queryRenderedFeatures(e.point, {
        layers: ['projects', 'pipelines'],
      });
      const feature = features.length && features[0];
      if (feature && feature.layer.id === 'projects' && !feature.properties.cluster) {
        const { id } = feature;
        const project = projects.features.find(p => p.id === id);
        if (project) handleMarkerClick(project);
      } else if (feature && feature.layer.id === 'pipelines') {
        handleMarkerClick(feature);
      }
    });
  }, [navigate, map, projects, handleMarkerClick]);

  useEffect(() => {
    if (!map || !piplines) return;
    map.getSource('pipelines').setData(piplines);
  }, [map, piplines]);
  
  return (
    <div className="map-view">
      <Map 
        onLoad={setMap} 
        handleMarkerClick={handleMarkerClick}
        showFullscreenControl={showFullscreenControlRef.current}
      />
      <Logos map={map} />
      <Popup 
        map={map}
        project={activeProject}
        activeStatus={activeStatus}
        sidepanelLeftIsOpen={sidepanelLeftIsOpen}
        setActiveProject={setActiveProject}
        sidepanelRightIsOpen={sidepanelRightIsOpen}
        setSidepanelRightIsOpen={setSidepanelRightIsOpen}
      />
      <DataControls 
        activeStatus={activeStatus}
        setActiveStatus={setActiveStatus}
        sidepanelLeftIsOpen={sidepanelLeftIsOpen}
        setSidepanelLeftIsOpen={setSidepanelLeftIsOpen}
        setSidepanelLeftContent={setSidepanelLeftContent}
        searchValue={searchValue}
      />
      <Button 
        className="map-view__form-button"
        onClick={() => setFormIsOpen(true)}
        disableElevation
      >
        Submit hydrogen initiatives
      </Button>
      <SidepanelLeft
        map={map}
        sidepanelLeftIsOpen={sidepanelLeftIsOpen}
        setSidepanelLeftIsOpen={setSidepanelLeftIsOpen}
        features={projects?.features || []}
        activeStatus={activeStatus}
        setActiveStatus={setActiveStatus}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        filters={filters}
        setFilters={setFilters}
        setActiveProject={setActiveProject}
        setSidepanelRightIsOpen={setSidepanelRightIsOpen}
        sidepanelLeftContent={sidepanelLeftContent}
        setSidepanelLeftContent={setSidepanelLeftContent}
      />
      <div 
        className={`map-view__overlay${sidepanelRightIsOpen 
          || formIsOpen ? '' : 'map-view__overlay--hidden'}`} 
        onClick={() => {
          setSidepanelRightIsOpen(false);
          setFormIsOpen(false);
          navigate('/', { replace: true });
        }}
      />
      <SidepanelRight 
        activeProject={activeProject}
        sidepanelRightIsOpen={sidepanelRightIsOpen}
        setSidepanelRightIsOpen={setSidepanelRightIsOpen}
        setSidepanelLeftIsOpen={setSidepanelLeftIsOpen}
        setFormIsOpen={setFormIsOpen}
      />
      <Form 
        formIsOpen={formIsOpen}
        setFormIsOpen={setFormIsOpen}
      />
    </div>
  )
}

export default MapView;