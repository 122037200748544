/* eslint-disable max-lines */
import { useEffect, useRef } from 'react';
import mapboxgl from 'mapbox-gl';
import Spiderfy from '@nazka/map-gl-js-spiderfy';
import style from '@css/theme.style.json';
import FullscreenControl from './controls/FullscreenControl/FullscreenControl';

import { addMarkersToMap } from './map.utils';

import sources from './style/sources';
import layers from './style/layers';

import 'mapbox-gl/dist/mapbox-gl.css';
import './map.styl';

function Map({ onLoad, handleMarkerClick, showFullscreenControl }) {
  const map = useRef(null);

  useEffect(() => {
    if (map.current) return;
    mapboxgl.accessToken = import.meta.env.VITE_MAPBOX_TOKEN;

    map.current = new mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/nazka-mapps/clt2p3rm2007r01p8cat6h720',
      center: [4.5, 50.75],
      zoom: 6.1,
      minZoom: 6.1,
      maxZoom: 12,
      maxBounds: [[-0.5, 47.5], [9.5, 53.5]],
      attributionControl: false,
    });

    map.current.addControl(new mapboxgl.AttributionControl({
      customAttribution: '<a href="https://nazka.be" rel="noopener" target="_blank">Nazka Mapps</a>',
    }), 'bottom-right');

    map.current.dragRotate.disable();
    map.current.touchZoomRotate.disableRotation();
    map.current.addControl(new mapboxgl.NavigationControl({ showCompass: false }), 'bottom-right');
    if (showFullscreenControl) map.current.addControl(new FullscreenControl(), 'bottom-right');

    map.current.loadImage(
      '/img/markers/sdf/circle-cover.png',
      (error, image) => {
        if (error) throw error;
        map.current.addImage('circle-cover-sdf', image, { sdf: true });
      },
    );

    map.current.loadImage(
      '/img/markers/sdf/circle.png',
      (error, image) => {
        if (error) throw error;
        map.current.addImage('circle-sdf', image, { sdf: true });
      },
    );
    
    map.current.on('load', async () => {
      await addMarkersToMap(map.current);

      sources.forEach(({ id, ...source }) => map.current.addSource(id, source));
      layers.forEach(({ beforeId, ...layer }) => map.current.addLayer(layer, beforeId));

      const maxLeaves = 99;
      const spiderfy = new Spiderfy(map.current, {
        onLeafClick: (f) => {
          handleMarkerClick(f);
        },
        minZoomLevel: 10,
        zoomIncrement: 1.5,
        spiderLegsWidth: 2,
        closeOnLeafClick: false,
        spiderLegsColor: '#fff',
        circleOptions: {
          leavesOffset: [3, 0],
        },
        spiderLeavesLayout: {
          'icon-image': ['concat', 'value-chain-', ['get', 'value_chain']],
          'icon-allow-overlap': true,
          'icon-size': 0.5,
        },
        maxLeaves,
        renderMethod: '3d',
      });

      const spiderfyStatuses = new Spiderfy(map.current, {
        onLeafClick: (f) => {
          handleMarkerClick(f);
        },
        minZoomLevel: 10,
        zoomIncrement: 1.5,
        spiderLegsAreHidden: true,
        closeOnLeafClick: false,
        circleOptions: {
          leavesOffset: [3, 0],
        },
        spiderLeavesLayout: {
          'icon-image': 'circle-cover-sdf',
          'icon-size': 0.25,
          'icon-allow-overlap': true,
        },
        spiderLeavesPaint: {
          'icon-color': [
            'match',
            ['get', 'status'],
            1, style.color.project['1'],
            2, style.color.project['2'],
            3, style.color.project['3'],
            4, style.color.project['4'],
            6, style.color.project['6'],
            style.color.project['5'],
          ],
        },
        maxLeaves,
        renderMethod: '3d',
      });

      spiderfy.applyTo('projects');
      spiderfyStatuses.applyTo('projects-cluster');

      map.current.on(
        'mouseenter', 
        [
          'projects',
          'pipelines',
          ...Array.from({ length: maxLeaves }).map((v, i) => `projects-spiderfy-leaf${i}`),
        ],
        () => { map.current.getCanvas().style.cursor = 'pointer' },
      );

      map.current.on(
        'mouseleave', 
        [
          'projects',
          'pipelines',
          ...Array.from({ length: maxLeaves }).map((v, i) => `projects-spiderfy-leaf${i}`),
        ],
        () => { map.current.getCanvas().style.cursor = '' },
      );

      onLoad(map.current);
    });
  }, [handleMarkerClick, map, onLoad]);

  return (
    <div id="map" className="map" />
  )
}

export default Map;