/* eslint-disable max-lines */
import { useQuery } from '@tanstack/react-query'
import { getPipelinePopup } from '@services/admin.service';
import Icon from '@library/Icon/Icon';

import './pipelines-info.styl';

function PipelinesInfo({ 
  mapOptions, setFormIsOpen, setSidepanelRightIsOpen, 
  setSidepanelLeftIsOpen,
}) {
  const { data } = useQuery({
    queryKey: ['pipelinePopupData'],
    queryFn: getPipelinePopup,
  });
  const pipelines = data?.attributes;

  return (
    <div className="pipelines-info">
      <div className="pipelines-info__header">
        <div className="pipelines-info__header__value-chain">
          <Icon
            className="pipelines-info__header__value-chain__marker" 
            icon={`../markers/value-chain-${pipelines?.value_chain?.data?.id}.png`}
          />
          <span className="pipelines-info__header__value-chain__text">{pipelines?.value_chain?.data?.attributes?.name}</span>
        </div>
        <div className="pipelines-info__header__logo" />
      </div>
      <h2 className="pipelines-info__title">
        {pipelines?.name}
      </h2>
      <h4 className="pipelines-info__application">
        {pipelines?.application?.data?.attributes?.name}
      </h4>
      <div className="pipelines-info__block">
        <h3 className="pipelines-info__block__title">
          About
        </h3>
        <div className="pipelines-info__block__text">
          {pipelines?.long_description 
            && mapOptions?.show_project_long_description ? (
              <span>{pipelines?.long_description}</span>
            ) : (
              <span>{pipelines?.short_description}</span>
            )}
        </div>
      </div>
      {!!pipelines?.partners?.data.length && (
      <div className="pipelines-info__block">
        <h3 className="pipelines-info__block__title">
          Partners
        </h3>
        <ul className="pipelines-info__block__list">
          {pipelines.partners.data.map((p) => {
            return (
              <li key={p.attributes.name} className="pipelines-info__block__list__item">
                <span>{p.attributes.name}</span>
              </li>
            )
          })}
        </ul>
      </div>
      )}
      {((pipelines?.contact_info && mapOptions?.show_project_contact_info) 
      || (pipelines?.website && mapOptions?.show_project_website)) && (
      <div className="pipelines-info__find-out">
        <h4>Find out more?</h4>
        <p>{`Please get in touch for more information about ${pipelines?.name}.`}</p>
        <div className="pipelines-info__find-out__website">
          {pipelines?.website && mapOptions?.show_project_website && (
          <a 
            href={pipelines?.website} 
            className="pipelines-info__find-out__website__url"
            target="_blank"
            rel="noreferrer"
          >
            <Icon 
              icon="link.svg"
              className="pipelines-info__find-out__website__icon"
            />
            {'Visit website >'}
          </a>
          )}
          {pipelines?.contact_info && mapOptions?.show_project_contact_info && (
          <div className="pipelines-info__find-out__website__url">
            <Icon 
              icon="email.svg"
              className="pipelines-info__find-out__website__icon"
            />
            {pipelines?.contact_info}
          </div>
          )}
        </div>
      </div>
      )}
      {pipelines?.logo?.data?.attributes?.url && (
        <div 
          className="pipelines-info__logo"
          style={{
            backgroundImage: `url(${pipelines
              ?.logo?.data?.attributes?.url})`, 
          }}
        />
      )}
      <div className="pipelines-info__change-information">
        <Icon 
          icon="pen.svg"
          className="pipelines-info__change-information__icon"
        />
        <h4>Change information</h4>
        <p>
          <span>
            Is certain information incorrect (anymore)? 
            Please let us know by clicking on the link below.
          </span>
        </p>
        <button 
          type="button"
          className="pipelines-info__change-information__button"
          onClick={() => {
            setFormIsOpen('pipelines');
            setSidepanelLeftIsOpen(false);
            setSidepanelRightIsOpen(false);
          }}
        >
          Request changes
        </button>
      </div>
    </div>
  )
}

export default PipelinesInfo;